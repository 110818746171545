import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import theme from "../../theme";
import WizardFooter from "./footer";

const useStyles = makeStyles((th) => ({
  input: {
    borderRadius: 0,
    border: th.borders[1],
    paddingTop: th.spacing(0),
    paddingBottom: th.spacing(0),
    paddingLeft: th.spacing(2),
  },
  divider: {
    borderBottom: th.borders[1],
  },
}));

const StepPriceComponent = ({
  flagShowCategoryIncome,
  onSubmit,
  onBack,
  bookingCategories,
  bookingCategoriesStatus,
  incomeBookingCategories,
  incomeBookingCategoriesStatus,
}) => {
  const classes = useStyles(theme);

  const { i18n } = useLingui();

  const [bookingCategory, setBookingCategory] = useState(null);
  const [incomeBookingCategory, setIncomeBookingCategory] = useState(null);

  const canSubmit =
    bookingCategoriesStatus !== "loading" &&
    bookingCategory &&
    (!flagShowCategoryIncome ||
      (flagShowCategoryIncome && incomeBookingCategoriesStatus !== "loading" && incomeBookingCategory));

  const handleSelectBookingCategory = ({ target }) => {
    setBookingCategory(target.value);
  };

  const handleSelectIncomeBookingCategory = ({ target }) => {
    setIncomeBookingCategory(target.value);
  };

  const handleSubmit = () => {
    onSubmit({
      bookingCategory,
      incomeBookingCategory,
    });
  };

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" gridGap={10} mt={2} mb={3}>
        <Typography variant="subtitle1">
          <Trans>Seleccione la categoría de reserva*</Trans>
        </Typography>
        {bookingCategoriesStatus === "loading" ? (
          <Box display="flex" alignItems="center" justifyContent="center" width={220} height={30}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Select
            value={bookingCategory}
            onChange={handleSelectBookingCategory}
            style={{ width: "220px", height: "34px" }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: { vertical: "bottom", horizontal: "left" },
              transformOrigin: { vertical: "top", horizontal: "left" },
            }}
            label={i18n._(t`Seleccione la categoría de reserva`)}
            className={classes.input}
            id="booking-category-select"
          >
            {bookingCategories.map((b) => (
              <MenuItem key={b.id} value={b.id}>
                {i18n._(t`${b.slug}`)}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>

      {flagShowCategoryIncome && (
        <Box display="flex" flexDirection="row" alignItems="center" gridGap={10} mb={3}>
          <Typography variant="subtitle1">
            <Trans>Seleccione la categoría de ingreso*</Trans>
          </Typography>
          {incomeBookingCategoriesStatus === "loading" ? (
            <Box display="flex" alignItems="center" justifyContent="center" width={220} height={30}>
              <CircularProgress size={20} />
            </Box>
          ) : (
            <Select
              value={incomeBookingCategory}
              onChange={handleSelectIncomeBookingCategory}
              style={{ width: "220px", height: "34px" }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                transformOrigin: { vertical: "top", horizontal: "left" },
              }}
              label={i18n._(t`Seleccione la categoría de ingreso`)}
              className={classes.input}
              id="income-category-select"
            >
              {incomeBookingCategories.map((b) => (
                <MenuItem key={b.uid} value={b.uid}>
                  {b.code} - {b.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
      )}

      <Box className={classes.divider} />
      <WizardFooter step={3} onNext={handleSubmit} canSubmit={canSubmit} onBack={onBack} />
    </>
  );
};

export default StepPriceComponent;
